import axios from '@/plugins/axios';

class BucketService {
  upload(data, progress = false, uploadCallBack = null) {
    this.data = data;
    return axios('bucket').post('store', this.data, {
      onUploadProgress: (progressEvent) => {
        if (progress) {
          uploadCallBack(Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ));
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  deleteFile(id) {
    this.id = id;
    return axios('bucket').delete(`/delete/${id}`)
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  deleteAll() {
    this.id = null;
    return axios('bucket').delete('/delete-all')
      .then((response) => response.data)
      .catch((error) => Promise.reject(error.response.data));
  }

  uploadFile(data, progress = false, uploadCallBack = null) {
    this.data = data;
    return axios('v1').post('bucket/upload', this.data, {
      onUploadProgress: (progressEvent) => {
        if (progress) {
          uploadCallBack(Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          ));
        }
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new BucketService();
