<template>
  <div class="custom-select relative" :tabindex="tabindex" @blur="open = false">
    <div class="selected" style="padding-left: 10px;" :class="{ open: open }" @click="open = !open">
      <div>
        <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
          <div class="icon-category-overview" :style="`margin-right:0px; width: 25px; height: 25px; padding-top:0px;margin: 5px; margin-top: 6px;background: ${colorDefault}`">
            <img :src="`/assets/images/icons/png/${selected}.png`" />
          </div>
        </span>
      </div>
    </div>
    <div class="items" :class="{ selectHide: !open }" style="overflow-y: scroll;">
      <ul class="list-items">
        <li v-for="(item, i) in icons" :key="i">
          <div
            @click="
              selected = item;
              open = false;
              $emit('input', item);
            "
          >
            <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
              <div class="icon-category-overview" style="margin-right:0px;">
                <img :src="`/assets/images/icons/png/${item}.png`" style="margin-top: 0px;"/>
              </div>
            </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    colorDefault: {
      type: String,
      required: false,
      default: '#7b93a4',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      icons: [
        'cash',
        'coin',
        'castle',
        'tag',
        'car',
        'phone',
        'silverware',
        'martini',
        'food',
        'gift',
        'heart-pulse',
        'home-variant',
        'dumbbell',
        'taxi',
        'beach',
        'bike',
        'biohazard',
        'bomb',
        'bone',
        'brightness-3',
        'briefcase',
        'cake',
        'cart',
        'cat',
        'content-cut',
        'cow',
        'delete-variant',
        'duck',
        'dumbbell',
        'earth',
        'silverware-variant',
        'flash',
        'gas-station',
        'glass-tulip',
        'gender-female',
        'gender-male',
        'human-male-female',
        'pokeball',
        'school',
        'airplane',
        'ambulance',
        'anchor',
        'apple',
        'baby-buggy',
        'bank',
        'beach',
        'bowling',
        'bus-side',
      ],
      selected: this.default
        ? this.default
        : this.options != null
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("input", this.selected);
  },
};
</script>

<style scoped>
.items {
  position: absolute;
  width: 270px;
  margin-top: 5px;
  max-height: 200px;
}
.image-item {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #e8e8e8;
  overflow: hidden;
  cursor: default;
  border-radius: 50%;
}
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 40px;
  line-height: 47px;
}

.custom-select .selected {
  background-color: #0a0a0a;
  border-radius: 6px;
  border: 1px solid #666666;
  color: #fff;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  height: 40px;
}

.custom-select .selected.open {
  border: 1px solid #ad8225;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 22px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: #fff transparent transparent transparent;
}
.list-items li:hover {
  background: rgb(235, 235, 235);
}

.custom-select .items {
  color: #fff;
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  border-right: 1px solid #ad8225;
  border-left: 1px solid #ad8225;
  border-bottom: 1px solid #ad8225;
  position: absolute;
  background-color: #0a0a0a;
  left: 0;
  right: 0;
  z-index: 1;
}

.custom-select .items div {
  color: #fff;
  cursor: pointer;
  user-select: none;
}

.selectHide {
  display: none;
}
.custom-select {
  height: 40px;
  line-height: 35px;
}
.custom-select .selected {
  background-color: #fff;
  color: #6d819c;
  border: 1.5px solid #dcdcdc !important;
}
.custom-select .selected::after {
  position: absolute;
  content: "";
  top: 18px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid transparent;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  border-color: #6d819c transparent transparent transparent;
}
.custom-select .items {
  color: #6d819c;
  background-color: #fff;
  border: 1.5px solid #dcdcdc !important;
}
.custom-select .items div {
  color: #6d819c;
}
.icon-category-overview {
  background-color: rgb(123, 147, 164);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 10px;
  text-align: center;
}
.list-items {
  margin: 0px;
  list-style: none;
  padding: 0px;
  margin-left: 10px;
}
.list-items li {
  float: left;
  margin: 10px;
  margin-left: 0px;
}
.icon-category-overview {
  padding-top: 8%;
}
.icon-category-overview img {
  margin-top: -10px;
}
</style>
