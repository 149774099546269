<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Upload de Imagem</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <form @submit.prevent="save" class="form-horizontal">
          <div class="modal-body">
            <div class="form-body p10">
              <div class="form-group row">
                <div class="col-md-12">
                  <div style="height:45px;" v-if="!showDrop">
                    <div class="input-group pull-right ml-5 inherit btn-heading" style="float: right; width: auto;">
                      <div class="input-group-prepend">
                        <button class="btn btn-success to-right no-radius border-radius-left btn-sm" v-if="!showDrop" @click="cropImage" type="button">Salvar</button>
                        <button class="btn btn-danger to-right color-white no-radius border-radius-right btn-sm" v-if="!showDrop" @click="resetCrop" type="button"><i class="fas fa-times color-white"></i></button>
                      </div>
                    </div>
                  </div>
                  <drag-drop :isUnic="true" :callBackFiles="callBackFiles" v-if="showDrop"></drag-drop>
                  <img ref="source"  :class="{ 'hide': showDrop }" :src="objectUrl" style="width: 100%; max-width: 100%; max-height: 350px;">
                </div>
                <div class="col-md-12" v-if="showPreview">
                  <label class="control-label text-right">Preview</label>
                  <div class="image-cover preview">
                    <img :src="previewCropped" class="image-preview"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import debounce from 'lodash/debounce';
import DragDrop from './DragDrop.vue';

export default {
  components: {
    DragDrop,
  },
  mixins: [Modal],
  props: {
    id: {
      type: String,
    },
    showPreview: {
      type: Boolean,
    },
    callBack: {
      type: Function,
    },
  },
  data() {
    return {
      cropper: null,
      zoom: 0,
      lastZoom: 0,
      srcImage: null,
      objectUrl: null,
      showDrop: true,
      file: null,
      img: null,
      name: null,
      previewCropped: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 257),
      form: {
      },
    };
  },
  mounted() {
    this.resetCrop();
  },
  created() {
    this.resetCrop();
    if (this.data) {
      this.maxWidth = this.data.maxWidth;
      this.maxHeight = this.data.maxHeight;
    }
  },
  methods: {
    zoomChange(value, index) {
      const zoomNow = value / 100;
      console.log(value, index);
      console.log(this.lastZoom);
      console.log(zoomNow);
      if (this.lastZoom > zoomNow) {
        this.cropper.zoom(-(zoomNow + 0.3));
      } else {
        this.cropper.zoom(+zoomNow);
      }

      this.lastZoom = zoomNow;
    },
    resetCrop() {
      this.showDrop = true;
      if (this.cropper) {
        this.cropper.destroy();
      }
    },
    cropImage() {
      const cropImage = this.cropper.getCroppedCanvas().toDataURL();
      const data = this.cropper.getData();
      data.url = cropImage;
      data.file = this.file;
      data.name = this.$store.state.crop.name;
      console.log(this.$store.state.crop.name);
      this.hide(true);
      this.$root.$emit('call.crop', data);
      this.resetCrop();
    },
    cancelCrop() {
      this.hide(true);
      this.$root.$emit('call.crop', null);
    },
    setupCropper(selectedFile) {
      if (this.cropper) {
        this.cropper.destroy();
      }

      if (this.objectUrl) {
        window.URL.revokeObjectURL(this.objectUrl);
      }

      if (!selectedFile) {
        this.cropper = null;
        this.objectUrl = null;
        this.previewCropped = null;
        return;
      }

      this.objectUrl = window.URL.createObjectURL(selectedFile);
      this.$nextTick(this.setupCropperInstance);
    },
    callBackFiles(files) {
      this.file = files[0];
      const reader = new FileReader();
      reader.onload = (function (aImg) {
        console.log(aImg);
        return function (e) {
          if (e.target.result != null) {
            this.srcImage = e.target.result;
          }
        };
      }(this.img));
      reader.readAsDataURL(files[0]);
      this.setupCropper(files[0]);
      this.showDrop = false;
    },
    updatePreview(event) {
      console.log(event);
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/png');
    },
    setupCropperInstance() {
      const params = {
        crop: this.debouncedUpdatePreview,
        viewMode: 3,
        dragMode: 'move',
        cropBoxMovable: true,
        movable: true,
        zoomable: true,
        cropBoxResizable: true,
        zoomOnWheel: true,
        wheelZoomRatio: 1,
        responsive: true,
        minCropBoxWidth: 200,
        minCropBoxHeight: 200,
      };
      this.cropper = new Cropper(this.$refs.source, params);
    },
  },
};
</script>

<style lang="scss">
  .image-cover{
    border: 1px solid rgba(120,141,180,.3);
    width: 100%;
    height: 200px;
  }
  .cropper-container{
    width: 100% !important;
  }
</style>
