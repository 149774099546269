<template>
  <div class="row">
    <div class="col-sm-12">
    </div>
    <div class="col-md-12 col-lg-12">
      <div id="drag-box-upload" class="drag-box-upload">
        <div class="dropz shadow-card" id="dropz">
          <i class="fas fa-download"></i>
          <p class='zone-text'>Arraste ou clique aqui para selecionar o arquivo.</p>
          <p style="font-size: 13px;" v-if="this.$store.state.crop.infoSize">Dimensão indicada: {{this.$store.state.crop.infoSize}}</p>
          <input name="file" type="file" id="dropz-file" multiple accept="image/jpeg, image/png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    isFile: {
      type: Boolean,
    },
    isUnic: {
      type: Boolean,
    },
    callBackFiles: {
      type: Function,
    },
  },
  data() {
    return {
      globalFiles: [],
      accpetType: '',
    };
  },
  mounted() {
    const input = document.getElementById('dropz-file');
    if (input.getAttribute('type') === 'file') {
      input.addEventListener('change', this.handleFiles);
    }
  },
  methods: {
    handleFiles(event) {
      const files = event.currentTarget.files;
      this.accpetType = event.currentTarget.accept;
      if (this.isUnic) {
        this.globalFiles = [];
      }
      for (let i = 0; i < files.length; i += 1) {
        this.globalFiles.push(files[i]);
      }
      this.renderImages();
    },
    renderImages() {
      if (document.getElementsByClassName('zone-text')[0]) {
        document.getElementsByClassName('zone-text')[0].remove();
      }
      document.querySelectorAll('.drop-img').forEach((element) => {
        element.remove();
      });
      for (let i = 0; i < this.globalFiles.length; i += 1) {
        const file = this.globalFiles[i];
        // if (!file.type.startsWith('image/')){ continue }
        const dropImg = document.createElement('div');
        dropImg.classList.add('drop-img');

        if (this.isUnic) {
          dropImg.classList.add('file-unic');
        }

        const h3 = document.createElement('h3');
        h3.classList.add('h3-type');
        if (file.type === null || file.type === '') {
          h3.innerHTML = this.accpetType;
        } else {
          h3.innerHTML = file.type;
        }

        dropImg.append(h3);

        // element remove
        const remove = document.createElement('span');
        if (!this.isUnic) {
          remove.innerHTML = '×';
          remove.classList.add('p-remove');
          remove.setAttribute('i-file', i);
        }

        // Img
        const img = document.createElement('img');
        img.file = file;

        dropImg.append(remove);
        dropImg.append(img);
        document.getElementById('dropz').append(dropImg);

        const reader = new FileReader();
        reader.onload = (function (aImg) {
          return function (e) {
            if (e.target.result != null && !this.isFile) {
              aImg.src = e.target.result;
            } else {
              aImg.src = '';
            }
          };
        }(img));
        reader.readAsDataURL(file);
        this.updateInputInfos();
      }
    },
    updateInputInfos() {
      const dataTransfer = new DataTransfer();
      for (let i = 0; i < this.globalFiles.length; i += 1) {
        dataTransfer.items.add(this.globalFiles[i]);
      }
      document.getElementById('dropz-file').files = dataTransfer.files;
      document.querySelectorAll('.p-remove').forEach((element) => {
        element.addEventListener('click', (event) => {
          const i = event.target.getAttribute('i-file');
          this.globalFiles.splice(i, 1);

          this.renderImages();

          if (this.globalFiles.length === 0) {
            document.getElementById('dropz').append('<i class="fas fa-download"></i><p class="zone-text">Arraste ou clique aqui para selecionar o arquivo.</p>');
          }
        });
      });
      this.callBackFiles(this.globalFiles);
    },
  },
};
</script>

<style lang="scss">

.dropz {
  background: #fafafa;
  border-radius: 5px;
  border: 1px dashed rgb(183, 188, 192);
  border-image: none;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 250px;
  overflow: hidden;
  cursor: pointer;
}

.dropz  input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropz p{
  text-align: center;
  font-size: 20px;
  color: #ccc;
}

.h3-type {
  position: absolute;
  text-align: center;
  display: block;
  width: 100%;
  bottom: 0px;
  background: #00000059;
  color: #fff;
  font-size: 15px;
  padding: 3px;
}

.drop-img {
  width: 20%;
  float: left;
  position: relative;
  border-radius: 5px;
  height: 90px;
  margin: 5px;
  overflow: hidden;
  border: solid 1px #ccc;
  background: #fff;
  box-shadow: 2px 2px 2px #ccc;
}

.drop-img .p-remove{
  position: absolute;
  color: #fff;
  right: 0;
  display: block;
  text-align: center;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  background: #ec2d38;
}
.drop-img .p-remove:hover{
  cursor: pointer;
  background: #d52b35;
}
.drop-img img{
  width: 100%;
  min-height: 100%;
}

.file-unic{
  float: none !important;
  margin: 0 auto !important;
  margin-top: 10% !important;
}
.fa-download{
  text-align: center;
  padding-top: 10%;
  font-size: 60px;
  display: block;
  color: #ccc;
}
</style>
